import React, { useState, useEffect } from 'react';

function App() {
  const [prompt, setPrompt] = useState('隨機抽取3個中文字');
  const [poem, setPoem] = useState(null);
  const [elaboration, setElaboration] = useState(null);

  useEffect(() => {
    if (prompt) {
      generatePoem();
    }
  }, [prompt]);

  const generatePoem = async () => {
    const requestBody = {
      message: prompt
    };

    try {
      const response = await fetch('http://localhost:3000/conversation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      const data = await response.json();
      setPoem(data.response);
      setElaboration(null);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const generateElaboration = async () => {
    if (poem) {
      const newPrompt = `${poem}，以風水財運角度，為每一個字給予財運解釋`;

      const requestBody = {
        message: newPrompt
      };

      try {
        const response = await fetch('http://localhost:3000/conversation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody)
        });

        const data = await response.json();
        setElaboration(data.response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <div>
      <h1>命運之輪</h1>
      <button onClick={() => setPrompt('隨機抽取3個中文字')}>隨機抽取3個中文字</button> <br/>
      {poem && <p>{poem}</p>}
      <button onClick={generateElaboration}>財運解釋</button> <br/>
      {elaboration && <p>{elaboration}</p>}
    </div>
  );
}

export default App;
